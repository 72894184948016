export const IdImage = () => {
  const LOCAL_STORAGE_KEY = 'id-image';

  const set = (image: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, image);
  };

  const retrieve = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  };

  const clear = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return {set, retrieve, clear};
};
