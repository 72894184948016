import {h} from 'preact';
import {ROUTES} from '../constants';
import Home from '../assets/svgs/home.svg';
import More from '../assets/svgs/more.svg';

export const TabBar = () => {
  const links = [
    {route: ROUTES.wallet, title: 'Wallet', icon: <Home />},
    {route: ROUTES.more, title: 'Mehr', icon: <More />},
  ];

  return (
    <nav className="w-full flex justify-between bg-white border-t border-slate-200 z-10">
      {links.map(({route, title, icon}) => (
        <a
          href={route}
          className="w-full flex flex-col items-center inline-block text-center pt-2 pb-1"
        >
          {icon}
          <span className="text-xs">{title}</span>
        </a>
      ))}
    </nav>
  );
};
