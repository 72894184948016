import {FunctionalComponent, h} from 'preact';

type Props = {labels: string[]; active: number};
export const ProgressTracker: FunctionalComponent<Props> = ({labels, active}) => {
  return (
    <div className="w-full flex justify-between z-10">
      {labels.map((label, index, arr) => {
        const isActive = active == index;
        const isDone = active > index;
        const isDoneOrActive = isActive || isDone;

        return (
          <div className="flex grow last:grow-0 items-center">
            <span
              className={`rounded-full h-6 w-6 text-center text-white z-10 ${
                isDoneOrActive ? 'bg-surface-primary-default' : 'bg-surface-primary-subdued'
              }`}
            >
              {isDone ? '✓' : label}
            </span>
            {arr.length > index + 1 && (
              <span className="h-2 grow bg-surface-primary-subdued -mx-1 flex">
                <span
                  className={`bg-surface-primary-default transition-[width] transition-slowest ease ${
                    isDone ? 'w-full rounded-r-lg' : 'w-0'
                  }`}
                ></span>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
