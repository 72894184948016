import {FunctionalComponent, h, JSX} from 'preact';
import {forwardRef} from 'preact/compat';

type Props = JSX.HTMLAttributes<HTMLInputElement> & {label?: string};

export const Input: FunctionalComponent<Props> = forwardRef<HTMLInputElement, Props>(
  ({id, label, className, ...props}, ref) => {
    id = id || (Math.random() + 1).toString(36).substring(7);
    return (
      <div className={`${className} flex flex-col gap-1`}>
        {label && (
          <label for={id} class="block text-sm font-semibold text-default">
            {label}
          </label>
        )}
        <input
          id={id}
          ref={ref}
          {...props}
          class="bg-surface-subdued border border-subdued text-subdued text-sm rounded block w-full py-2 px-2.5"
        />
      </div>
    );
  },
);
