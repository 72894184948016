import {FunctionalComponent, h, JSX} from 'preact';
import {forwardRef} from 'preact/compat';

type Props = JSX.HTMLAttributes<HTMLInputElement> & {label?: string};

export const Radio: FunctionalComponent<Props> = forwardRef<HTMLInputElement, Props>(
  ({id, label, className, ...props}, ref) => {
    id = id || (Math.random() + 1).toString(36).substring(7);
    return (
      <div className={`${className || ''} flex items-center justify-start gap-1`}>
        <input id={id} {...props} ref={ref} type="radio" />
        {label && (
          <label for={id} class="block text-label font-semibold text-default">
            {label}
          </label>
        )}
      </div>
    );
  },
);
