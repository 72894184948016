type IDCode = string;

export const IDCodes = () => {
  const LOCAL_STORAGE_KEY = 'id-codes';

  const set = (codes: IDCode[]) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(codes));
  };

  const list = () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
  };

  const clear = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return {set, list, clear};
};
