import {FunctionalComponent, JSX, h} from 'preact';

type Props = JSX.HTMLAttributes<HTMLButtonElement> & {
  variant?: Variant;
};
type Variant = 'filled' | 'text';

export const Button: FunctionalComponent<Props> = ({children, variant = 'filled', ...props}) => {
  const variantStyles = {
    base: 'text-sm tracking-wider font-medium rounded-md px-4 py-2 disabled:opacity-50',
    filled: 'text-white bg-surface-primary-default',
    text: 'text-primary-default transition-colors',
  };

  return (
    <button {...props} className={`${variantStyles.base} ${variantStyles[variant]}`}>
      {children}
    </button>
  );
};
