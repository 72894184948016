export const publicKey = Uint8Array.from([
  185, 239, 54, 30, 62, 126, 192, 154, 51, 241, 10, 178, 39, 34, 112, 104, 97, 114, 39, 140, 123,
  55, 181, 49, 24, 109, 49, 149, 223, 116, 33, 8,
]);

/* The QR-Codes won't be generated in this application. Since it is just a showcase there
 * is no harm done to add a hardcoded secret key here. */
export const secretKey = Uint8Array.from([
  44, 224, 140, 137, 128, 158, 255, 104, 149, 107, 220, 20, 53, 120, 244, 101, 193, 129, 244, 137,
  51, 137, 213, 136, 114, 220, 65, 117, 66, 134, 151, 72, 185, 239, 54, 30, 62, 126, 192, 154, 51,
  241, 10, 178, 39, 34, 112, 104, 97, 114, 39, 140, 123, 55, 181, 49, 24, 109, 49, 149, 223, 116,
  33, 8,
]);

export const ROUTES = {
  wallet: '/',
  more: '/more',
  registerId: '/register-id',
  displayCodes: '/display-codes',
  addCode: '/add-code',
  playground: '/playground',
  scanApp: '/scan-app',
  generateCode: '/scan-app/generate-code',
};
