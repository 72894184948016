import * as cbor from '@stablelib/cbor';
import {Buffer} from 'buffer';
import {zlibSync, unzlibSync} from 'fflate';
import nacl from 'tweetnacl';

const PREFIX = 'IDI:';

export const decodeQRCode = (qrCode: string, publicKey: Uint8Array) => {
  const signed = Buffer.from(qrCode.slice(PREFIX.length), 'base64');
  const payload = nacl.sign.open(signed, publicKey);
  if (!payload) throw new Error('Verification failed');
  const decompressed = unzlibSync(payload);
  const raw = cbor.decode(decompressed);
  return raw;
};

export type ImageCodePayload = {
  sequenceNumber: number;
  sequenceLength: number;
  payload: string;
};
export type IDCodePayload = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sequenceNumber: number;
  sequenceLength: number;
};
export type AuthCodePayload = {
  meta: {
    issuer: string;
    description: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  payload: string;
  logo?: string;
};

const encodePayload = (payload: any, secretKey: Uint8Array) => {
  const buffer = cbor.encode(payload);
  const compr = zlibSync(buffer, {level: 9});
  const signed = nacl.sign(compr, secretKey);
  const qrcodeStr = `${PREFIX}${Buffer.from(signed).toString('base64')}`;
  return qrcodeStr;
};

/** Helpers to enforce payload types */
export const encodeAuthPayload = (payload: AuthCodePayload, secretKey: Uint8Array) => {
  return encodePayload(payload, secretKey);
};

export const encodeIdPayload = (payload: IDCodePayload, secretKey: Uint8Array) => {
  return encodePayload(payload, secretKey);
};

export const encodeImagePayload = (payload: ImageCodePayload, secretKey: Uint8Array) => {
  return encodePayload(payload, secretKey);
};
