const CAPTURE_OPTIONS: MediaStreamConstraints = {
  audio: false,
  video: {facingMode: 'environment', width: {ideal: 1000}, height: {ideal: 1000}},
};

const VIDEO_DEVICE_KIND = 'videoinput';

/**
 * Opens a camera stream. In case the user has multiple back cameras the camera labels are sorted and the first one is selected.
 * Note: This is based on the assumption that the correct lense has a label like: 'camera 0', which is favored over 'camera 1'.
 * This a dirty hack that is based on this stackoverflow thread:
 * https://stackoverflow.com/questions/59636464/how-to-select-proper-backfacing-camera-in-javascript
 */
export const requestMediaStream = async () => {
  try {
    let devices = await navigator.mediaDevices.enumerateDevices();
    let mediaStream: MediaStream | null;
    let backIndices: Array<number>;
    mediaStream = null;

    // Permission was already given to read out the devices labels:
    if (isVideoInputLabeled(devices)) {
      backIndices = getBackCameraIndices(devices);
    }
    // No access was given to the labels:
    else {
      // First we need an active stream to get the deviceId and label returned by enumerateDevices:
      mediaStream = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
      // Again enumerate the devices, now they have a label information:
      devices = await navigator.mediaDevices.enumerateDevices();
      backIndices = getBackCameraIndices(devices);
    }

    if (backIndices.length > 1) {
      const bestIdx = backIndices.reduce((acc, curr) => {
        return bestIdx == -1 || devices[curr].label < devices[acc].label ? curr : acc;
      }, -1);

      const deviceOptions: MediaStreamConstraints = {
        audio: false,
        video: {
          deviceId: {exact: devices[bestIdx].deviceId},
          width: {ideal: 1000},
          height: {ideal: 1000},
        },
      };

      if (mediaStream != null) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }

      try {
        mediaStream = await navigator.mediaDevices.getUserMedia(deviceOptions);
      } catch (error) {
        // This should not happen, but it is hard to test...
        // In case the wrong deviceId was given, use at least the default camera:
        if ((error as any)?.name === 'OverconstrainedError') {
          mediaStream = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
        } else {
          throw error;
        }
      }
    }
    // Just open the default media stream if it is not open already:
    else if (mediaStream === null) {
      mediaStream = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
    }

    return mediaStream;
  } catch (error) {
    throw error;
  }
};

const isBackCamera = (label: string): boolean => {
  const conditions = ['back', 'rück', 'rear'];
  return conditions.some((l) => label.includes(l));
};

/**
 * Returns a list containing indices of back cameras.
 * @param devices
 * @returns
 */
const getBackCameraIndices = (devices: MediaDeviceInfo[]): Array<number> => {
  const indices = new Array<number>();

  let idx = 0;
  for (const device of devices) {
    if (device.kind !== VIDEO_DEVICE_KIND) continue;
    if (!isBackCamera(device.label)) continue;
    indices.push(idx);
    idx++;
  }

  return indices;
};

/**
 * Checks if the array of MediaDeviceInfo is readable, meaning a label information is provided.
 * This is only the case if the user granted access to getUserMedia.
 * @param devices
 * @returns
 */
const isVideoInputLabeled = (devices: MediaDeviceInfo[]): boolean => {
  for (const device of devices) {
    if (device.kind === VIDEO_DEVICE_KIND && device.label === '') {
      return false;
    }
  }

  return true;
};
