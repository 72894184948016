import {h, FunctionalComponent} from 'preact';
import {route} from 'preact-router';
import {useEffect} from 'preact/hooks';
import {ROUTES} from '../constants';
import {useCodes} from '../context/CodesCtx';

export const withId = (
  Component: FunctionalComponent<any>,
  redirect: string = ROUTES.registerId,
) => {
  return (props) => {
    const {idCodes} = useCodes();

    useEffect(() => {
      if (idCodes.length) return;
      route(redirect);
    }, []);

    return <Component {...props} />;
  };
};
