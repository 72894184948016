import {h} from 'preact';
import verifyName from 'rockdoc-name-matching-ts';
import {AuthCodePayload, IDCodePayload, ImageCodePayload, decodeQRCode} from '../../utils/qrcode';
import {publicKey} from '../../constants';
import {useEffect, useState} from 'preact/hooks';
import {ZBarScanner} from '../../components';

type ScannerStatus = 'scanning' | 'processing' | 'valid' | 'invalid';
const ScanApp = () => {
  const [codes, setCodes] = useState<string[]>([]);
  const [status, setStatus] = useState<ScannerStatus>('scanning');
  const [sequenceLength, setSequenceLength] = useState<number | null>(null);
  const [authPayload, setAuthPayload] = useState<any>(null);
  const [imgData, setImgData] = useState('');

  const onScan = (code: string) => {
    const payload = decodeQRCode(code, publicKey);
    if (!sequenceLength && payload.sequenceLength) setSequenceLength(payload.sequenceLength);
    setCodes(pushIfNotExists(codes, payload));
  };

  const pushIfNotExists = (codes: string[], payload: string) => {
    codes.push(JSON.stringify(payload));
    return [...new Set(codes)];
  };

  useEffect(() => {
    if (status !== 'scanning') return;
    if (sequenceLength == null) return;
    if (codes.length < 1 + sequenceLength) return; // 1 for authCode, which doesn't have sequence number

    setStatus('processing');

    const {id, imgData, authCode} = extractPayloadInfos(codes);
    setImgData(imgData);

    const isValid = validateCodes([id as IDCodePayload, authCode as AuthCodePayload]);
    const newStatus = isValid ? 'valid' : 'invalid';
    setStatus(newStatus);

    setAuthPayload(authCode);
  }, [codes, sequenceLength]);

  const extractPayloadInfos = (codes: string[]) => {
    const sorted = sortCodes(codes.map((code) => JSON.parse(code)));
    const {0: id, [sorted.length - 1]: authCode, ...images} = sorted;
    const imgPrefix = 'data:image/webp;base64,';
    const imgData = `${imgPrefix}${Object.values(images as {[key: number]: ImageCodePayload})
      .map((elem) => elem.payload)
      .join('')}`;

    return {id, imgData, authCode};
  };

  const validateCodes = (codes: [IDCodePayload, AuthCodePayload]) => {
    const idCodeName = `${codes[0].firstName} ${codes[0].lastName}`;
    const authCodeName = `${codes[1].meta.firstName} ${codes[1].meta.lastName}`;
    const nameMatch = verifyName(idCodeName, authCodeName);

    const dateOfBirthMath =
      Date.parse(codes[0].dateOfBirth) === Date.parse(codes[1].meta.dateOfBirth);
    console.log('idCode: ', codes[0].dateOfBirth, Date.parse(codes[0].dateOfBirth));
    console.log('authCode: ', codes[1].meta.dateOfBirth, Date.parse(codes[1].meta.dateOfBirth));
    console.log('dateOfBirthMath: ', dateOfBirthMath);
    console.log('nameMatch: ', nameMatch, idCodeName, authCodeName);

    return nameMatch && dateOfBirthMath;
  };

  const sortCodes = (codes: (IDCodePayload | AuthCodePayload | ImageCodePayload)[]) => {
    return [...codes].sort((a: any, b: any) => {
      if (a.sequenceNumber === undefined && b.sequenceNumber === undefined) return 0;
      if (a.sequenceNumber === undefined) return 1;
      if (b.sequenceNumber === undefined) return -1;

      return a.sequenceNumber - b.sequenceNumber;
    });
  };

  return (
    <div className="flex flex-col gap-8 h-full">
      <span className="text-lg">
        {status} - {codes.length} aus {sequenceLength ? 1 + sequenceLength : 'TBA'} codes gescanneds
      </span>
      {status === 'scanning' ? (
        <ZBarScanner onScan={onScan} />
      ) : (
        <div className="flex gap-4">
          <img id="img" src={imgData} className="w-52" />
          <div className="flex flex-col gap-2">
            <div>
              <h2 className="text-lg font-bold">{(authPayload as AuthCodePayload).meta.issuer}</h2>
              <span>{(authPayload as AuthCodePayload).meta.description}</span>
            </div>
            <span>{(authPayload as AuthCodePayload)?.payload}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanApp;
