import {h} from 'preact';
import {Route, Router, getCurrentUrl, route} from 'preact-router';
import {ROUTES} from '../constants';
import {CodesProvider, useCodes} from '../context/CodesCtx';

import Wallet from '../routes/wallet';
import More from '../routes/more';
import RegisterId from '../routes/register-id';
import AddCode from '../routes/add-code';
import DisplayCodes from '../routes/display-codes';
import Playground from '../routes/playground';

import ScanApp from '../routes/scan-app/scan-app';
import GenerateCode from '../routes/scan-app/generate-code';
import {TabBar} from './TabBar';
import {useEffect} from 'preact/hooks';

import {signal} from '@preact/signals';

const App = () => {
  const {idCodes} = useCodes();
  const currRoute = signal(getCurrentUrl());

  useEffect(() => {
    if (idCodes.length) {
      if (getCurrentUrl() !== ROUTES.registerId) return;
      route(ROUTES.wallet);
      return;
    }
  }, [idCodes]);

  const onRoutChange = () => {
    currRoute.value = getCurrentUrl();
  };

  return (
    <div className="flex flex-col h-full">
      <main className="grow relative py-4 px-4">
        <Router onChange={onRoutChange}>
          <Route path={ROUTES.playground} component={Playground} />
          {/* Wallet */}
          <Route path={ROUTES.wallet} component={Wallet} />
          <Route path={ROUTES.more} component={More} />

          <Route path={ROUTES.registerId} component={RegisterId} />
          <Route path={ROUTES.displayCodes} component={DisplayCodes} />
          <Route path={ROUTES.addCode} component={AddCode} />

          {/* Scan-App */}
          <Route path={ROUTES.scanApp} component={ScanApp} />
          <Route path={ROUTES.generateCode} component={GenerateCode} />
        </Router>
      </main>
      {currRoute.value !== ROUTES.registerId && <TabBar />}
    </div>
  );
};

const Wrapper = (props) => (
  <CodesProvider>
    <App {...props} />
  </CodesProvider>
);

export {Wrapper as App};
