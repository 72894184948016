import {h} from 'preact';
import {Button, Input} from '../../components';
import {useRef} from 'preact/hooks';
import {useForm} from 'react-hook-form';
import QRCode from 'qrcode';
import {encodeAuthPayload} from '../../utils/qrcode';
import {secretKey} from '../../constants';

type FormValues = {
  issuer: string;
  description: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  payload: string;
  logo: string;
};

const GenerateCode = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {register, handleSubmit, formState} = useForm({mode: 'onChange'});

  const generatQRCode = async ({payload, logo, ...meta}: FormValues) => {
    const _payload = {meta, logo, payload};
    const qrString = encodeAuthPayload(_payload, secretKey);
    QRCode.toCanvas(canvasRef.current, qrString);
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="text-lg font-bold">Gemma, Gemma - Wohin?</h2>
      </div>
      <form className="flex flex-col gap-16" onSubmit={handleSubmit(generatQRCode)}>
        <div className="flex flex-col gap-4">
          <Input placeholder="Issuer" label="Issuer" {...register('issuer', {required: true})} />
          <Input placeholder="burgenland" label="Logo" {...register('logo')} />
          <Input
            placeholder="Description"
            label="Description"
            {...register('description', {required: true})}
          />
          <Input
            placeholder="Vorname"
            label="Vorname"
            {...register('firstName', {required: true})}
          />
          <Input
            placeholder="Nachname"
            label="Nachname"
            {...register('lastName', {required: true})}
          />
          <Input
            placeholder="Geburtsdatum"
            label="Geburtsdatum"
            type="date"
            {...register('dateOfBirth', {required: true})}
          />
          <Input placeholder="Payload" label="Payload" {...register('payload')} />
        </div>
        <Button disabled={!formState.isValid} type="submit">
          Generate Code
        </Button>
      </form>
      <div className="flex justify-center">
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
};

export default GenerateCode;
