export type AuthorisationCode = {
  issuer: string;
  description: string;
  code: string;
};

export const AuthorisationCodes = () => {
  const LOCAL_STORAGE_KEY = 'authorisation-codes';

  const list = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
  const create = (code: AuthorisationCode) => {
    const codes = list();
    codes.push(code);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(codes));
  };

  const clear = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return {list, create, clear};
};
