import {h} from 'preact';
import {createCtx} from './createCtx';
import {useEffect, useState} from 'preact/hooks';
import {db} from '../services/db';

export type AuthorisationCode = {
  issuer: string;
  description: string;
  code: string;
  payload: string;
  logo?: string;
};

export type IDCode = string;

interface CodeCtx {
  idCodes: IDCode[];
  setIdCodes: (codes: IDCode[]) => void;
  authorisationCodes: AuthorisationCode[];
  addAuthorisationCode: (code: AuthorisationCode) => void;
  idImage: string;
  setIdImage: (string) => void;

  // development phase
  clearAll: () => void;
}

const [useCtx, CtxProvider] = createCtx<CodeCtx>();

const CodesProvider = (props) => {
  const [idCodes, _setIdCodes] = useState<IDCode[]>([]);
  const [idImage, _setIdImage] = useState<string>('');
  const [authorisationCodes, setAuthorisationCodes] = useState<AuthorisationCode[]>([]);

  useEffect(() => {
    setAuthorisationCodes(db.authCodes.list());
    _setIdCodes(db.idCodes.list());
    _setIdImage(db.idImage.retrieve());
  }, []);

  const addAuthorisationCode = (code: AuthorisationCode) => {
    db.authCodes.create(code);
    setAuthorisationCodes((codes) => [...codes, code]);
  };

  const setIdCodes = (codes: IDCode[]) => {
    db.idCodes.set(codes);
    _setIdCodes(codes);
  };

  const setIdImage = (image: string) => {
    db.idImage.set(image);
    _setIdImage(image);
  };

  const clearAll = () => {
    db.idCodes.clear();
    db.authCodes.clear();
    _setIdCodes([]);
    setAuthorisationCodes([]);
  };

  return (
    <CtxProvider
      value={{
        addAuthorisationCode,
        authorisationCodes,
        idCodes,
        setIdImage,
        idImage,
        setIdCodes,
        clearAll,
      }}
      {...props}
    />
  );
};

export {CodesProvider, useCtx as useCodes};
