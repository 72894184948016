export enum WasmCall {
  INIT_WASM,
  PASSPORT_DETECTION,
  PASSPORT_VERIFY,
}

export enum WasmReturn {
  INIT_WASM,
  PASSPORT_DETECTION,
  PASSPORT_VERIFY,
}

export enum ScannerType {
  PASSPORT,
  ID,
  DRIVING_LICENSE,
}

export enum DetectionStatus {
  STATUS_OK,
  STATUS_NOT_FOUND,
  STATUS_OUT_OF_BOUNDARY,
  STATUS_OCCLUSION,
  STATUS_TOO_SMALL,
  STATUS_MEDIA_ERROR,
  STATUS_LOADING,
}

export interface Point {
  x: number;
  y: number;
}

export interface Rect extends Point {
  width: number;
  height: number;
}

interface EventData {
  eventType: number;
  scannerType: number;
}

export interface InitEventData extends EventData {
  eventType: WasmReturn.INIT_WASM;
}

interface PassPortEventData extends EventData {
  eventType: number;
  corners: Point[];
  outerBoundary: Point[];
}

export interface PassPortDetectionEventData extends PassPortEventData {
  eventType: WasmReturn.PASSPORT_DETECTION;
}

export interface PassportVerifyEventData extends PassPortEventData {
  eventType: WasmReturn.PASSPORT_VERIFY;
  imageData: Uint8ClampedArray;
  imageWidth: number;
  imageHeight: number;
  cStatusCode: number;
}
