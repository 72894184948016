import {FunctionalComponent, h} from 'preact';
import {useRef, useEffect} from 'preact/hooks';
import {requestMediaStream} from '../../utils/cameraUtils';
import Worker from 'worker-loader!./zbar-worker.js';

const SCANNER_FREQ = 100; // ms

type Props = {onScan: (code: string) => void};
export const ZBarScanner: FunctionalComponent<Props> = ({onScan}) => {
  const videoRef = useRef<HTMLVideoElement>();
  const mediaStream = useRef<MediaStream | null>();
  const hiddenCanvasRef = useRef<HTMLCanvasElement>();
  const canvasRef = useRef<HTMLCanvasElement>();
  const worker = useRef<Worker>(new Worker());

  useEffect(() => {
    requestMediaStream().then((stream) => {
      videoRef.current.srcObject = stream;
      requestAnimationFrame(tick);
      mediaStream.current = stream;
    });

    worker.current.onmessage = (msgEvent) => {
      if (msgEvent.data.symbol != 'QR-Code') return;
      onScan(msgEvent.data.data);
    };

    return () => {
      stopWebCam();
      terminateWorker();
    };
  }, []);

  const tick = () => {
    if (!videoRef.current) return;
    if (videoRef.current.readyState !== videoRef.current.HAVE_ENOUGH_DATA) {
      return requestAnimationFrame(tick);
    }

    const h = videoRef.current.videoHeight;
    const w = videoRef.current.videoWidth;
    if (hiddenCanvasRef.current.height !== h) {
      hiddenCanvasRef.current.width = w;
      hiddenCanvasRef.current.height = h;
      resizeCanvasToVideo(canvasRef.current);
    }

    const canvasCtx = hiddenCanvasRef.current.getContext('2d', {willReadFrequently: true});
    canvasCtx.drawImage(videoRef.current, 0, 0, w, h);

    const imgData = canvasCtx.getImageData(0, 0, w, h);
    if (imgData.data) worker.current.postMessage(imgData);

    setTimeout(tick, SCANNER_FREQ);
  };

  const resizeCanvasToVideo = (canvas: HTMLCanvasElement) => {
    const {height, width} = videoRef.current.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;
  };

  const stopWebCam = () => {
    videoRef.current?.pause();
    mediaStream.current?.getTracks().forEach((track) => track.stop());
  };

  const terminateWorker = () => {
    if (!worker.current) return;
    worker.current.terminate();
  };

  return (
    <div className="h-full relative">
      <canvas ref={canvasRef} className="absolute z-10" />
      <video ref={videoRef} autoPlay playsInline muted className="absolute h-full w-full" />
      <canvas ref={hiddenCanvasRef} hidden />
    </div>
  );
};
