import {h, Component, createRef} from 'preact';
import {
  WasmCall,
  WasmReturn,
  ScannerType,
  DetectionStatus,
  Point,
  Rect,
  InitEventData,
  PassPortDetectionEventData,
  PassportVerifyEventData,
} from './types';
import {requestMediaStream} from '../../utils/cameraUtils';

import Worker from 'worker-loader!./docScan-worker.js';

const REFRESH_MS = 250;
const WAIT_WHILE_OK_MS = 2500;

const HELPER_LINE_WIDTH = 5;
const HELPER_STROKE_COLOR = '#32CD32';

const HINTS: {[key: number]: string} = {
  [DetectionStatus.STATUS_OK]: 'OK, bitte stillhalten',
  [DetectionStatus.STATUS_NOT_FOUND]: 'Bitte Ausweis positionieren',
  [DetectionStatus.STATUS_OUT_OF_BOUNDARY]: 'Bitte die gesamte Ausweisseite scannen',
  [DetectionStatus.STATUS_OCCLUSION]: 'Bitte Überdeckungen / Reflexionen vermeiden',
  [DetectionStatus.STATUS_TOO_SMALL]: 'Bitte näher kommen',
  [DetectionStatus.STATUS_MEDIA_ERROR]: 'Fehler beim Zugriff auf Kamera',
  [DetectionStatus.STATUS_LOADING]: 'Bitte warte, lade Scanner...',
};

const getHintMessage = (status: number | null) => {
  return HINTS[status] || 'Bitte Ausweistyp auswählen';
};

function updateStatus(
  corners: Point[] | null,
  passPortBoundary: Point[] | null,
  boundaryRect: Rect | null,
  scannerType: ScannerType,
): number {
  if (!corners || corners.length === 0 || !passPortBoundary || !boundaryRect) {
    return DetectionStatus.STATUS_NOT_FOUND;
  }

  for (let i = 0; i < 4; i++) {
    const point = passPortBoundary[i];
    if (!point) {
      return DetectionStatus.STATUS_NOT_FOUND;
    }
    if (
      point.x < boundaryRect.x ||
      point.y < boundaryRect.y ||
      point.x > boundaryRect.x + boundaryRect.width ||
      point.y > boundaryRect.y + boundaryRect.height
    ) {
      return DetectionStatus.STATUS_OUT_OF_BOUNDARY;
    }
  }
  // Check for occlusions in the MRZ region of the passport:

  if (scannerType === ScannerType.PASSPORT) {
    // Determine the mrz rectangle:
    let sx = Infinity;
    let sy = Infinity;
    let ex = -Infinity;
    let ey = -Infinity;
    corners.forEach(({x, y}) => {
      if (x < sx) sx = x;
      if (x > ex) ex = x;
      if (y < sy) sy = y;
      if (y > ey) ey = y;
    });

    const mrzWidth = ex - sx;
    const mrzHeight = ey - sy;
    if (mrzHeight > 0) {
      const optimalRatio = 120 / 15;
      const ratio = mrzWidth / mrzHeight;
      if (ratio < optimalRatio - 1) {
        return DetectionStatus.STATUS_OCCLUSION;
      }
    } else {
      return DetectionStatus.STATUS_OUT_OF_BOUNDARY;
    }
  }

  // Check if the user should come closer:
  let minX = Infinity;
  let maxX = -1;
  for (let i = 0; i < 4; i++) {
    const point = passPortBoundary[i];
    if (!point) return DetectionStatus.STATUS_NOT_FOUND;
    if (point.x < minX) minX = point.x;
    if (point.x > maxX) maxX = point.x;
  }
  const idWidth = maxX - minX;
  if (idWidth / boundaryRect.width < 0.4) return DetectionStatus.STATUS_TOO_SMALL;

  return DetectionStatus.STATUS_OK;
}

interface ScannerProps {
  handleFile(file: Blob): void;
  scannerType: ScannerType;
}

interface ScannerState {
  isWorkerReady: boolean;
  corners: Point[] | null;
  outerBoundary: Point[] | null;
  detectionStatus: number | null;
  passedAt: number | null;
  boundaryRect: Rect | null;
  mediaStream: MediaStream | null;
  tickTimer: NodeJS.Timeout | null;
  doVerification: boolean;
  isImageFocused: boolean;
  cntNotFocused: number;
}

export class IDScanner extends Component<ScannerProps, ScannerState> {
  private readonly fileRef = createRef<HTMLInputElement>();
  private readonly offscreenCanvasElement = document.createElement('canvas');
  private readonly videoRef = createRef<HTMLVideoElement>();
  private readonly canvasRef = createRef<HTMLCanvasElement>();
  private worker: null | Worker = null;

  constructor() {
    super();
    this.state = {
      isWorkerReady: false,
      corners: null,
      outerBoundary: null,
      detectionStatus: null,
      passedAt: null,
      boundaryRect: null,
      mediaStream: null,
      tickTimer: null,
      doVerification: false,
      isImageFocused: true,
      cntNotFocused: 0,
    };
  }

  componentDidMount() {
    this.worker = new Worker();
    const {scannerType} = this.props;
    this.worker.postMessage({eventType: WasmCall.INIT_WASM, scannerType});

    this.worker.onerror = (event) => console.error('Error in worker:', event);
    this.worker.onmessage = (event) => this.handleWorkerMessage(event);
    this.startMediaStream();
  }

  componentWillUnmount() {
    this.clearTicker();
    this.stopWebCam();
    this.terminateWorker();
  }

  // Somehow the TypeScript version used during deploy thinks MessageEvent has no type parameter.
  private handleWorkerMessage(event: MessageEvent /*<InitEventData|PassportDetectionEventData>*/) {
    const data = event.data as InitEventData | PassPortDetectionEventData | PassportVerifyEventData;

    // The wasm file is loaded and compiled:
    if (data.eventType === WasmReturn.INIT_WASM) {
      this.setState({isWorkerReady: true, passedAt: null});
    }
    // A detection result is returned:
    else if (
      data.eventType === WasmReturn.PASSPORT_DETECTION ||
      data.eventType === WasmReturn.PASSPORT_VERIFY
    ) {
      // First determine the status of the detection:
      const {corners, outerBoundary, scannerType} = data;
      const detectionStatus = updateStatus(
        corners,
        outerBoundary,
        this.state.boundaryRect,
        scannerType,
      );

      let isNotFocused = false;

      // The status is OK and the imagedata is returned by the worker:
      if (
        detectionStatus === DetectionStatus.STATUS_OK &&
        data.eventType === WasmReturn.PASSPORT_VERIFY
      ) {
        const {imageData, imageWidth, imageHeight, cStatusCode} = data;

        // Check if the ID is out in focus:
        const isFocused = cStatusCode != 1;
        let {cntNotFocused} = this.state;

        // Save the image if it is focused or if it was unfocused for a certain number of retries:
        if (isFocused || cntNotFocused >= 6) {
          this.setState({isImageFocused: isFocused});
          if (isFocused) {
            this.createImage(imageData, imageWidth, imageHeight).then(({blob}) => {
              this.props.handleFile(blob);
            });
          }
          this.stopWebCam();
          this.setState({isWorkerReady: false});
          return;
        }
        cntNotFocused++;
        this.setState({cntNotFocused});
      }

      let {passedAt} = this.state;
      let doVerification;
      if (detectionStatus !== DetectionStatus.STATUS_OK || isNotFocused) {
        passedAt = null;
        doVerification = false;
      } else if (passedAt === null) {
        passedAt = Date.now();
      } else if (Date.now() - passedAt >= WAIT_WHILE_OK_MS) {
        // The next step is now the verification by the worker:
        doVerification = true;
      }

      this.setState({
        corners,
        doVerification,
        outerBoundary,
        passedAt,
        detectionStatus,
        isWorkerReady: true,
      });
    }
  }

  // Convert imageData: Uint8ClampedArray to an image-blob using a hidden canvas
  private async createImage(imageData: Uint8ClampedArray, imageWidth: number, imageHeight: number) {
    const canvas = this.offscreenCanvasElement.getContext('2d');
    if (!canvas) throw new Error('offscreenCanvasElement.getContext("2d") returned null');

    const image = canvas.createImageData(imageWidth, imageHeight);
    image.data.set(imageData);

    canvas.putImageData(image, 0, 0);
    const mimeType = 'image/jpeg';
    const quality = 0.9;

    type PromiseType = {mimeType: string; blob: Blob};
    return new Promise<PromiseType>((resolve, reject) => {
      const onBlob = (blob: Blob) => {
        if (!blob) reject(new Error('offscreenCanvasElement.toBlob() returned null'));
        resolve({mimeType, blob});
      };
      this.offscreenCanvasElement.toBlob(onBlob, mimeType, quality);
    });
  }

  private async startMediaStream() {
    if (!this.videoRef.current) return;
    await requestMediaStream().then((mediaStream) => {
      try {
        const newState: Partial<ScannerState> = {mediaStream};
        this.videoRef.current.srcObject = mediaStream;
        if (this.state.tickTimer === null) {
          newState.tickTimer = setTimeout(this.tick, REFRESH_MS);
        }
        this.setState(newState);
      } catch (error) {
        console.error(error);
        this.setState({detectionStatus: DetectionStatus.STATUS_MEDIA_ERROR});
      }
    });
  }

  private clearTicker() {
    if (this.state.tickTimer === null) return;
    clearTimeout(this.state.tickTimer);
  }

  private terminateWorker() {
    if (!this.worker) return;
    try {
      this.worker.terminate();
    } finally {
      this.worker.onmessage = null;
      this.worker.onerror = null;
      this.worker = null;
    }
  }

  private stopWebCam() {
    this.videoRef.current?.pause();
    this.state.mediaStream?.getTracks().forEach((track) => track.stop());
  }

  private tick = () => {
    const newState: Partial<ScannerState> = {tickTimer: null};
    const video = this.videoRef.current;

    if (!video) return this.setState(newState);

    // loadingMessage.innerText = '⌛ Loading video...';
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const {offscreenCanvasElement} = this;

      const offscreenCanvas = offscreenCanvasElement.getContext('2d', {willReadFrequently: true});
      if (!offscreenCanvas) {
        console.error("offscreenCanvasElement.getContext('2d') failed!");
      } else {
        const width = video.videoWidth;
        const height = video.videoHeight;

        offscreenCanvasElement.width = width;
        offscreenCanvasElement.height = height;

        offscreenCanvas.drawImage(video, 0, 0, width, height);
        const imageData = offscreenCanvas.getImageData(0, 0, width, height);

        const {isWorkerReady, doVerification} = this.state;

        if (this.worker && isWorkerReady) {
          newState.isWorkerReady = false;
          const event = doVerification ? WasmCall.PASSPORT_VERIFY : WasmCall.PASSPORT_DETECTION;
          this.worker.postMessage(
            {
              eventType: event,
              imageData: imageData.data,
              imageWidth: imageData.width,
              imageHeight: imageData.height,
              scannerType: this.props.scannerType,
            },
            [imageData.data.buffer],
          );
        }
      }

      const canvasElement = this.canvasRef.current;
      if (canvasElement) {
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;
        const canvas = canvasElement.getContext('2d');
        const {corners} = this.state;
        if (canvas) {
          canvas.clearRect(0, 0, canvasElement.width, canvasElement.height);
          if (corners) {
            canvas.beginPath();
            canvas.moveTo(corners[0].x, corners[0].y);
            canvas.lineTo(corners[1].x, corners[1].y);
            canvas.lineTo(corners[2].x, corners[2].y);
            canvas.lineTo(corners[3].x, corners[3].y);
            canvas.closePath();
            canvas.lineWidth = HELPER_LINE_WIDTH;
            canvas.strokeStyle = HELPER_STROKE_COLOR;
            canvas.stroke();
          }

          if (
            this.props.scannerType === ScannerType.ID ||
            this.props.scannerType === ScannerType.DRIVING_LICENSE
          ) {
            const {outerBoundary} = this.state;
            if (outerBoundary) {
              canvas.beginPath();
              canvas.moveTo(outerBoundary[0].x, outerBoundary[0].y);
              canvas.lineTo(outerBoundary[1].x, outerBoundary[1].y);
              canvas.lineTo(outerBoundary[2].x, outerBoundary[2].y);
              canvas.lineTo(outerBoundary[3].x, outerBoundary[3].y);
              canvas.closePath();
              canvas.lineWidth = HELPER_LINE_WIDTH;
              canvas.strokeStyle = HELPER_STROKE_COLOR;
              canvas.stroke();
            }
          }

          const canvasWidth = canvasElement.width;
          const canvasHeight = canvasElement.height;

          // TODO: I am not sure if the offset should be an absolute value.
          const offsetPerc = 0.07;
          // The default case is portrait:
          const offset =
            canvasHeight > canvasWidth ? canvasWidth * offsetPerc : canvasHeight * offsetPerc;

          const boundaryWidth = canvasWidth - 2 * offset;
          let boundaryHeight = (boundaryWidth * 9) / 12;
          let yStart: number;
          const maxBoundaryHeight = canvasHeight - 2 * offset;

          if (boundaryHeight > maxBoundaryHeight) {
            boundaryHeight = maxBoundaryHeight;
            yStart = offset;
          } else {
            yStart = (canvasHeight - boundaryHeight) / 2;
          }

          const boundaryRect = {
            x: offset,
            y: yStart,
            width: boundaryWidth,
            height: boundaryHeight,
          };
          newState.boundaryRect = boundaryRect;
        }
      }
    }

    newState.tickTimer = setTimeout(this.tick, REFRESH_MS);

    return this.setState(newState);
  };

  render() {
    const {detectionStatus, isImageFocused} = this.state;

    return (
      <div class="mb-6">
        {isImageFocused ? (
          <div>
            <div>
              <p class="badge bg-white text-wrap vertical-align:middle mb-2" style="height: 3em;">
                {getHintMessage(detectionStatus)}
              </p>
              <div class="relative h-auto w-full">
                <video class="w-full top-0" ref={this.videoRef} autoPlay playsInline muted />
                <canvas
                  class="z-10 absolute w-full top-0 left-0 right-0 bottom-0"
                  ref={this.canvasRef}
                  id="canvas"
                />
              </div>
            </div>
          </div>
        ) : (
          <div class="mb-3">
            <p class="text-rk-red text-sm">Leider ist das aufgenommene Bild zu unscharf!</p>
            <p class="text-sm mb-8 mt-4">
              Bitte lade deinen Ausweis{' '}
              <a
                href="javascript:;"
                style="text-decoration:underline;"
                onClick={(event) => {
                  event.preventDefault();
                  this.fileRef.current?.click();
                }}
              >
                manuell hinauf.
              </a>
            </p>
          </div>
        )}

        <div class="mb-3">
          <p class="text-sm mb-8 mt-4">
            Probleme bei der automatischen Erkennung? Als Alternative kannst du{' '}
            <a
              href="javascript:;"
              style="text-decoration:underline;"
              onClick={(event) => {
                event.preventDefault();
                this.fileRef.current?.click();
              }}
            >
              hier tippen
            </a>{' '}
            um dein Ausweisfoto aus deiner Bildergalerie hinaufzuladen.
          </p>
        </div>

        <input
          class="hidden"
          type="file"
          accept="image/*"
          ref={this.fileRef}
          onChange={(event) => {
            this.stopWebCam();
            const {target} = event;
            if (target instanceof HTMLInputElement && target.files && target.files.length > 0) {
              const file = target.files[0];
              this.props.handleFile(file);
            }
          }}
        />
      </div>
    );
  }
}
